import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, functions } from '../../firebase';
import { doc, setDoc} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

function Callback() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    
    const validateUserData = (userData) => {
        if (!userData || !userData.user_id || !userData.primary_email) {
            throw new Error('Missing required user data from Etsy (user_id or email)');
        }
        
        return {
            user_id: userData.user_id,
            primary_email: userData.primary_email
        };
    };

    const validateShopData = (shopData) => {
        if (!shopData || !shopData.shop_id || !shopData.shop_name) {
            return null;
        }
        
        return {
            shop_id: shopData.shop_id,
            shop_name: shopData.shop_name,
            shop_url: shopData.url,
            shop_icon: shopData.icon_url_fullxfull
        };
    };

    useEffect(() => {
        const handleCallback = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                const code = params.get('code');
                const returnedState = params.get('state');
                
                const storedState = sessionStorage.getItem('etsy_oauth_state');
                if (returnedState !== storedState) {
                    throw new Error('State mismatch - possible CSRF attack');
                }

                const codeVerifier = sessionStorage.getItem('etsy_code_verifier');
                if (!codeVerifier) {
                    throw new Error('No code verifier found');
                }

                const exchangeTokenFn = httpsCallable(functions, 'exchangeToken');
                const result = await exchangeTokenFn({
                    code,
                    code_verifier: codeVerifier,
                });

                const { tokenData, userData, shopData } = result.data;
                
                const validatedUserData = validateUserData(userData);
                const validatedShopData = validateShopData(shopData);
                
                const etsyUserId = validatedUserData.user_id.toString();

                await setDoc(doc(db, 'tokens', etsyUserId), {
                    etsyUserId,
                    access_token: tokenData.access_token,
                    refresh_token: tokenData.refresh_token,
                    expires_in: tokenData.expires_in,
                    created_at: new Date().toISOString(),
                });

                await setDoc(doc(db, 'users', etsyUserId), {
                    etsyUserId,
                    email: validatedUserData.primary_email,
                    has_shop: !!validatedShopData,
                    created_at: new Date().toISOString(),
                    last_updated: new Date().toISOString(),
                    ...validatedShopData
                });

                navigate(`/app/signup?userId=${etsyUserId}`);

                sessionStorage.removeItem('etsy_oauth_state');
                sessionStorage.removeItem('etsy_code_verifier');

            } catch (err) {
                console.error('Auth callback error:', err);
                setError(err.message);
            }
        };

        handleCallback();
    }, [navigate]);

    if (error) {
        return (
            <div className="callback-error">
                <h1>Authentication Error</h1>
                <p>{error}</p>
                <p>We had some trouble connecting to your Etsy account.</p>
                <br />
                <button  onClick={() => navigate('/')}>Go Home</button>
            </div>
        );
    }

    return (
        <div className="callback-loading">
            <h1>Completing Authentication...</h1>
            <p>Please wait while we finish setting up your account.</p>
        </div>
    );
}

export default Callback;