// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB5Qi9ht6ZUCoVAX5IA7irRNGxDSKZkaJ4",
  authDomain: "my-linksy.firebaseapp.com",
  projectId: "my-linksy",
  storageBucket: "my-linksy.firebasestorage.app",
  messagingSenderId: "591415384818",
  appId: "1:591415384818:web:960be10d506036397d8e6b",
  measurementId: "G-SXK8BT9LZW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export { db, functions, auth };
