function Terms() {
    return (
        <div className="legal-container" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Terms of Service</h1>
            <p>Last updated: January 2024</p>

            <section>
                <p>By using Linksy ("the Service") provided by Understudy Technologies LLC ("we", "us", or "our"), you agree to these Terms of Service.</p>

                <h2>Service Description</h2>
                <p>Linksy is a link-in-bio tool for Etsy sellers that allows creation and management of personalized landing pages integrated with Etsy stores.</p>

                <h2>User Responsibilities</h2>
                <ul>
                    <li>Maintain accurate account information</li>
                    <li>Comply with Etsy's Terms of Service</li>
                    <li>Use the Service in accordance with applicable laws</li>
                    <li>Protect your account credentials</li>
                </ul>

                <h2>Intellectual Property</h2>
                <p>The Service and its content are owned by Understudy Technologies LLC. Users retain rights to their Etsy store content displayed through the Service.</p>

                <h2>Limitation of Liability</h2>
                <p>The Service is provided "as is" without warranties. We are not liable for any damages arising from your use of the Service.</p>

                <h2>Termination</h2>
                <p>We reserve the right to terminate or suspend access to the Service for violations of these terms or for any other reason at our discretion.</p>

                <h2>Changes to Terms</h2>
                <p>We may modify these terms at any time. Continued use of the Service constitutes acceptance of modified terms.</p>

                <h2>Contact</h2>
                <p>For questions about these terms, contact us at hello@understudy.app</p>
            </section>
        </div>
    );
}

export default Terms;