import { useState, useEffect } from 'react';
import { db, functions } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FaLink, FaPaintBrush, FaStore, FaShareAlt, FaExternalLinkAlt, FaShare } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Linksy from './LinksyPreview';
import LinksManager from './LinksManager';
import LinksyDesign from './DesignManager';
import ShopManager from './ShopManager';
import SocialManager from './SocialManager';
import Profile from './Profile';
import LoadingSpinner from './Components/LoadingSpinner';
import './Admin.css';


function Admin() {
    const [linksyData, setLinksyData] = useState(null);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeManager, setActiveManager] = useState('links'); // 'links' or 'design'
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [tokenData, setTokenData] = useState(null);
    const auth = getAuth();
    const navigate = useNavigate();
    const [listingsOffset, setListingsOffset] = useState(0);
    const [hasMoreListings, setHasMoreListings] = useState(true);
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                navigate('/app/auth');
            }
        });

        return () => unsubscribe();
    }, [auth, navigate]);

    const fetchListings = async (offset = 0) => {
        try {
            if (userData.shop_id && tokenData.access_token) {
                console.log('fetching listings');
                const fetchShopListings = httpsCallable(functions, 'fetchShopListings');
                const result = await fetchShopListings({ 
                    shopId: userData.shop_id,
                    token: tokenData.access_token,
                    limit: 100, // Maximum allowed by Etsy API
                    offset: offset
                });
                console.log(result.data);
                setListings(prevListings => [...prevListings, ...(result.data.results || [])]);
                // Update offset and check if more listings are available
                if (result.data.results.length < 100) {
                    setHasMoreListings(false);
                } else {
                    setListingsOffset(prevOffset => prevOffset + 100);
                }
            }
        } catch (error) {
            console.error('Error fetching listings:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!user) return;

                // Fetch linksy data
                const linksyDoc = await getDoc(doc(db, 'linksys', user.uid));
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                const tokenDoc = await getDoc(doc(db, 'tokens', user.uid));
                console.log(linksyDoc.data());
                console.log(userDoc.data());
                console.log(tokenDoc.data());
                if (linksyDoc.exists()) {
                    const linksyData = linksyDoc.data();
                    setLinksyData(linksyData);
                    const userData = userDoc.data();
                    setUserData(userData);
                    const tokenData = tokenDoc.data();
                    setTokenData(tokenData);

                    // Only fetch listings if we have shopId and token
                    if (userData.shop_id && tokenData.access_token) {
                        console.log('fetching listings');
                        const fetchShopListings = httpsCallable(functions, 'fetchShopListings');
                        const result = await fetchShopListings({ 
                            shopId: userData.shop_id,
                            token: tokenData.access_token
                        });
                        console.log(result.data);
                        setListings(result.data.results || []);
                    }
                }

                // Fetch initial listings
                await fetchListings(0);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [user]);

    const handleLinksUpdate = async (newLinks) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedData = {
                ...linksyData,
                links: newLinks
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData);
        } catch (error) {
            console.error('Error updating links:', error);
        }
    };

    const handleDesignUpdate = async (newDesign) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedData = {
                ...linksyData,
                ...newDesign
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData);
        } catch (error) {
            console.error('Error updating design:', error);
        }
    };

    const handleAboutUpdate = async (newAbout) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedData = {
                ...linksyData,
                bio: newAbout
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData); 
        } catch (error) {
            console.error('Error updating about:', error);
        }
    };

    const handleListingToggle = async (listing) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedListings = linksyData.selectedListings || [];
            const existingIndex = updatedListings.findIndex(l => l.listing_id === listing.listing_id);

            if (existingIndex >= 0) {
                updatedListings.splice(existingIndex, 1);
            } else {
                // Keep the entire listing object
                updatedListings.push(listing);
            }

            const updatedData = {
                ...linksyData,
                selectedListings: updatedListings
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData);
        } catch (error) {
            console.error('Error updating selected listings:', error);
        }
    };

    const handleSocialUpdate = async (newSocialLinks) => {
        try {
            const userId = auth.currentUser?.uid;
            if (!userId) return;

            const updatedData = {
                ...linksyData,
                socialLinks: newSocialLinks
            };

            await updateDoc(doc(db, 'linksys', userId), updatedData);
            setLinksyData(updatedData);
        } catch (error) {
            console.error('Error updating social links:', error);
        }
    };

    const copyToClipboard = async () => {
        try {
            const url = `${window.location.origin}/${linksyData?.shopName}`;
            await navigator.clipboard.writeText(url);
            // You might want to add a toast notification here
            alert('Link copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    if (loading || !linksyData) {
        return <LoadingSpinner />;
    }

    return (
        <div className="admin-container">
            {/* Navigation Column */}
            <div className="admin-nav">
                <div className="admin-nav-header">
                    <div className="admin-logo" onClick={() => navigate('/app/admin')}>Linksy</div>
                </div>
                
                <div className="admin-nav-buttons">
                    <button 
                        className={`nav-button ${activeManager === 'links' ? 'active' : ''}`}
                        onClick={() => setActiveManager('links')}
                    >
                        <FaLink style={{ marginRight: '8px' }} /> Links
                    </button>
                    <button 
                        className={`nav-button ${activeManager === 'design' ? 'active' : ''}`}
                        onClick={() => setActiveManager('design')}
                    >
                        <FaPaintBrush style={{ marginRight: '8px' }} /> Design
                    </button>
                    <button
                        className={`nav-button ${activeManager === 'shop' ? 'active' : ''}`}
                        onClick={() => setActiveManager('shop')}
                    >
                        <FaStore style={{ marginRight: '8px' }} /> Shop
                    </button>
                    <button
                        className={`nav-button ${activeManager === 'social' ? 'active' : ''}`}
                        onClick={() => setActiveManager('social')}
                    >
                        <FaShareAlt style={{ marginRight: '8px' }} /> Social
                    </button>
                </div>

                <div className="admin-nav-footer">
                    <Profile shopName={linksyData?.shopName} />
                </div>
            </div>

            {/* Manager Column */}
            <div className="admin-manager">
                {activeManager === 'links' ? (
                    <LinksManager 
                        links={linksyData?.links || []}
                        onUpdate={handleLinksUpdate}
                    />
                ) : activeManager === 'design' ? (
                    <LinksyDesign 
                        design={linksyData}
                        onUpdate={handleDesignUpdate}
                    />
                ) : activeManager === 'shop' ? (
                    <ShopManager 
                        about={linksyData?.bio}
                        listings={listings}
                        selectedListings={linksyData?.selectedListings || []}
                        onUpdate={handleAboutUpdate}
                        onListingToggle={handleListingToggle}
                        fetchMoreListings={() => fetchListings(listingsOffset)}
                        hasMoreListings={hasMoreListings}
                    />
                ) : activeManager === 'social' ? (
                    <SocialManager 
                        socialLinks={linksyData?.socialLinks || []}
                        onUpdate={handleSocialUpdate}
                        userData={userData}
                    />
                ) : null}
            </div>

            {/* Preview Column */}
            <div className="admin-preview">
                <div className="iphone-mockup">
                    <div className="iphone-screen">
                        <Linksy data={linksyData} />
                    </div>
                </div>
                <div className="preview-actions">
                    <a 
                        href={`/${linksyData?.shopName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="preview-button"
                    >
                        <FaExternalLinkAlt />
                        Preview
                    </a>
                    <button 
                        onClick={copyToClipboard}
                        className="preview-button"
                    >
                        <FaShare />
                        Share
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Admin;