import { useState, useEffect } from 'react';
import './DesignManager.css';
import { fontOptions } from '../Config/fonts';

function DesignManager({ design, onUpdate }) {
    const [localDesign, setLocalDesign] = useState(design);

    const handleInteractionEnd = () => {
        onUpdate(localDesign);
    };

    const handleChange = (field, value) => {
        setLocalDesign(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    // Update local state when parent design prop changes
    useEffect(() => {
        setLocalDesign(design);
    }, [design]);

    return (
        <div className="linksy-design">

            <div className="design-section">
                <h4>Colors</h4>
                <div className="color-inputs">
                    <label>
                        Background
                        <input 
                            type="color"
                            value={localDesign.backgroundColor}
                            onChange={(e) => handleChange('backgroundColor', e.target.value)}
                            onBlur={handleInteractionEnd}
                        />
                    </label>
                    <label>
                        Text Color
                        <input 
                            type="color"
                            value={localDesign.textColor}
                            onChange={(e) => handleChange('textColor', e.target.value)}
                            onBlur={handleInteractionEnd}
                        />
                    </label>
                    <label>
                        Button Color
                        <input 
                            type="color"
                            value={localDesign.buttonColor}
                            onChange={(e) => handleChange('buttonColor', e.target.value)}
                            onBlur={handleInteractionEnd}
                        />
                    </label>
                    <label>
                        Button Text
                        <input 
                            type="color"
                            value={localDesign.buttonTextColor}
                            onChange={(e) => handleChange('buttonTextColor', e.target.value)}
                            onBlur={handleInteractionEnd}
                        />
                    </label>
                </div>
            </div>

            <div className="design-section">
                <h4>Typography</h4>
                <label>
                    Font Family
                    <select 
                        value={localDesign.fontFamily}
                        onChange={(e) => {
                            handleChange('fontFamily', e.target.value);
                            onUpdate({ ...localDesign, fontFamily: e.target.value });
                        }}
                        className="font-select"
                        onBlur={handleInteractionEnd}
                    >
                        {fontOptions.map(category => (
                            <optgroup key={category.label} label={category.label}>
                                {category.options.map(font => (
                                    <option 
                                        key={font.value} 
                                        value={font.value}
                                        style={{ fontFamily: font.value }}
                                    >
                                        {font.label}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </select>
                </label>

                <div className="size-inputs">
                    <label>
                        Title Size
                        <div className="size-control">
                            <input 
                                type="range"
                                min="16"
                                max="48"
                                value={localDesign.titleSize}
                                onChange={(e) => handleChange('titleSize', Number(e.target.value))}
                                onMouseUp={handleInteractionEnd}
                                onTouchEnd={handleInteractionEnd}
                            />
                            <span>{localDesign.titleSize}px</span>
                        </div>
                    </label>

                    <label>
                        Bio Size
                        <div className="size-control">
                            <input 
                                type="range"
                                min="12"
                                max="24"
                                value={localDesign.bioSize}
                                onChange={(e) => handleChange('bioSize', Number(e.target.value))}
                                onMouseUp={handleInteractionEnd}
                                onTouchEnd={handleInteractionEnd}
                            />
                            <span>{localDesign.bioSize}px</span>
                        </div>
                    </label>

                    <label>
                        Button Text Size
                        <div className="size-control">
                            <input 
                                type="range"
                                min="12"
                                max="24"
                                value={localDesign.buttonTextSize}
                                onChange={(e) => handleChange('buttonTextSize', Number(e.target.value))}
                                onMouseUp={handleInteractionEnd}
                                onTouchEnd={handleInteractionEnd}
                            />
                            <span>{localDesign.buttonTextSize}px</span>
                        </div>
                    </label>
                </div>
            </div>

            <div className="design-section">
                <h4>Button Style</h4>
                <label>
                    Hover Effect
                    <select
                        value={localDesign.buttonHoverEffect || 'none'}
                        onChange={(e) => handleChange('buttonHoverEffect', e.target.value)}
                        onBlur={handleInteractionEnd}
                    >
                        <option value="scale">Scale</option>
                        <option value="lift">Lift</option>
                        <option value="none">None</option>
                    </select>
                </label>

                {localDesign.buttonHoverEffect !== 'none' && (
                    <label>
                        Effect Intensity
                        <div className="size-control">
                            <input 
                                type="range"
                                min="1"
                                max="20"
                                value={localDesign.buttonHoverIntensity || 10}
                                onChange={(e) => handleChange('buttonHoverIntensity', Number(e.target.value))}
                                onMouseUp={handleInteractionEnd}
                                onTouchEnd={handleInteractionEnd}
                            />
                            <span>{localDesign.buttonHoverIntensity || 10}</span>
                        </div>
                    </label>
                )}

                <label>
                    Hover Opacity
                    <div className="size-control">
                        <input 
                            type="range"
                            min="50"
                            max="100"
                            value={localDesign.buttonHoverOpacity * 100}
                            onChange={(e) => handleChange('buttonHoverOpacity', Number(e.target.value) / 100)}
                            onMouseUp={handleInteractionEnd}
                            onTouchEnd={handleInteractionEnd}
                        />
                        <span>{Math.round(localDesign.buttonHoverOpacity * 100)}%</span>
                    </div>
                </label>

                <label>
                    Corner Radius
                    <div className="size-control">
                        <input 
                            type="range"
                            min="0"
                            max="24"
                            value={localDesign.buttonRadius}
                            onChange={(e) => handleChange('buttonRadius', Number(e.target.value))}
                            onMouseUp={handleInteractionEnd}
                            onTouchEnd={handleInteractionEnd}
                        />
                        <span>{localDesign.buttonRadius}px</span>
                    </div>
                </label>

                <label>
                    Border Color
                    <input 
                        type="color"
                        value={localDesign.buttonBorderColor}
                        onChange={(e) => handleChange('buttonBorderColor', e.target.value)}
                        onBlur={handleInteractionEnd}
                    />
                </label>

                <label>
                    Border Width
                    <div className="size-control">
                        <input 
                            type="range"
                            min="0"
                            max="5"
                            value={localDesign.buttonBorderWidth}
                            onChange={(e) => handleChange('buttonBorderWidth', Number(e.target.value))}
                            onMouseUp={handleInteractionEnd}
                            onTouchEnd={handleInteractionEnd}
                        />
                        <span>{localDesign.buttonBorderWidth}px</span>
                    </div>
                </label>

                <label>
                    Shadow Color
                    <input 
                        type="color"
                        value={localDesign.buttonShadowColor}
                        onChange={(e) => handleChange('buttonShadowColor', e.target.value)}
                        onBlur={handleInteractionEnd}
                    />
                </label>

                <label>
                    Shadow Spread
                    <div className="size-control">
                        <input 
                            type="range"
                            min="0"
                            max="24"
                            value={localDesign.buttonShadowSpread}
                            onChange={(e) => handleChange('buttonShadowSpread', Number(e.target.value))}
                            onMouseUp={handleInteractionEnd}
                            onTouchEnd={handleInteractionEnd}
                        />
                        <span>{localDesign.buttonShadowSpread}px</span>
                    </div>
                </label>
            </div>
        </div>
    );
}

export default DesignManager; 