import { useState, useEffect } from 'react';
import './ShopManager.css';
import InfiniteScroll from 'react-infinite-scroll-component';

function ShopManager({ about, onUpdate, listings, selectedListings = [], onListingToggle, fetchMoreListings, hasMoreListings }) {
    const [localAbout, setLocalAbout] = useState(about);

    const handleSubmitAbout = () => {
        onUpdate(localAbout);
    };

    // Reset local state when prop changes
    useEffect(() => {
        setLocalAbout(about);
    }, [about]);

    const formatPrice = (priceObj) => {
        if (!priceObj || !priceObj.amount || !priceObj.divisor) return '0.00';
        return (priceObj.amount / priceObj.divisor).toFixed(2);
    };

    const getListingImageUrl = (listing) => {
        if (listing.images?.[0]?.url_fullxfull) {
            return listing.images[0].url_fullxfull;
        }
        if (listing.images?.[0]?.url_fullxfull) {
            return listing.images[0].url_fullxfull;
        }
        return 'https://via.placeholder.com/75';
    };

    const isListingSelected = (listingId) => {
        return selectedListings.some(l => l.listing_id === listingId);
    };

    const handleSelectListing = (listing) => {
        onListingToggle(listing);
    };

    if (!listings || listings.length === 0) {
        return (
            <div className='shop-manager'>
                <h4>About</h4>
                <div className="about-section">
                    <textarea
                        value={localAbout}
                        onChange={(e) => setLocalAbout(e.target.value)}
                        placeholder="Tell your customers about your shop..."
                    />
                    <button 
                        onClick={handleSubmitAbout}
                        disabled={localAbout === about}
                    >
                        Update
                    </button>
                </div>
                <h4>Listings</h4>
                <p>No listings found.</p>
            </div>
        );
    }

    return (
        <div className='shop-manager'>
            <h4>About</h4>
            <div className="about-section">
                <textarea
                    value={localAbout}
                    onChange={(e) => setLocalAbout(e.target.value)}
                    placeholder="Tell your customers about your shop..."
                />
                <button 
                    onClick={handleSubmitAbout}
                    disabled={localAbout === about}
                >
                    Update
                </button>
            </div>
            <h4>Listings ({listings.length})</h4>
            <InfiniteScroll
                dataLength={listings.length}
                next={fetchMoreListings}
                hasMore={hasMoreListings}
                loader={<h4>Loading more listings...</h4>}
                className="listings-container"
            >
                {listings.map((listing) => {
                    const isSelected = isListingSelected(listing.listing_id);
                    return (
                        <div key={listing.listing_id} className='listing-item'>
                            <img 
                                src={getListingImageUrl(listing)}
                                alt={listing.title}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = 'https://via.placeholder.com/75';
                                }}
                            />
                            <div className="listing-details">
                                <p className="listing-title">{listing.title}</p>
                                <p className="listing-price">${formatPrice(listing.price)}</p>
                            </div>
                            <button 
                                onClick={() => handleSelectListing(listing)}
                                className={isSelected ? 'selected' : ''}
                            >
                                {isSelected ? 'Remove' : 'Add'}
                            </button>
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
}

export default ShopManager;