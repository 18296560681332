import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import Linksy from '../Admin/LinksyPreview';
import './PublicLinksy.css';
import LoadingSpinner from '../Admin/Components/LoadingSpinner';

function PublicLinksy() {
    const { shopName } = useParams();
    const [linksyData, setLinksyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Memoize the Linksy component with its data
    const memoizedLinksy = useMemo(() => {
        if (!linksyData) return null;
        return <Linksy data={linksyData} />;
    }, [linksyData]);

    useEffect(() => {
        const fetchLinksyData = async () => {
            try {
                // Query the linksys collection where shopName matches
                const linksysRef = collection(db, 'linksys');
                const q = query(linksysRef, where('shopName', '==', shopName));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    setError('Linksy page not found');
                    setLoading(false);
                    return;
                }

                // Get the first (and should be only) matching document
                setLinksyData(querySnapshot.docs[0].data());
                setLoading(false);
            } catch (err) {
                console.error('Error fetching linksy:', err);
                setError('Failed to load linksy page');
                setLoading(false);
            }
        };

        fetchLinksyData();
    }, [shopName]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div className="public-linksy-container error">{error}</div>;
    }

    return (
        <div className="public-linksy-container">
            {memoizedLinksy}
        </div>
    );
}

export default PublicLinksy; 