import { useState, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';
import { decode } from 'html-entities';
import './LinksyPreview.css';
import { SOCIAL_CHANNELS } from '../Config/social';

const LinksyHeader = memo(({ shopIcon, titleSize, shopName, bioSize, bio }) => (
    <div className="linksy-header">
        <img src={shopIcon} alt="Shop Icon" className="shop-icon avatar" />
        <h1 style={{ fontSize: `${titleSize}px` }}>{decode(shopName)}</h1>
        <p style={{ fontSize: `${bioSize}px` }}>{decode(bio)}</p>
    </div>
));

const SocialIcons = memo(({ socialLinks, textColor }) => (
    <div className="social-icons">
        {socialLinks?.map((link) => {
            const SocialIcon = SOCIAL_CHANNELS[link.platform]?.icon;
            return SocialIcon ? (
                <a
                    key={link.platform}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-icon-link"
                    style={{ color: textColor }}
                >
                    <SocialIcon />
                </a>
            ) : null;
        })}
    </div>
));

const LinksyLinks = memo(({ links, buttonStyle }) => (
    <div className="linksy-links">
        {links.map((link, index) => (
            <a
                key={index}
                href={link.url}
                className="linksy-link"
                target="_blank"
                rel="noopener noreferrer"
                style={buttonStyle}
            >
                {link.title && decode(link.title)}
            </a>
        ))}
    </div>
));

function Linksy({ data }) {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('links');
    
    // Memoize button styles calculation
    const buttonStyle = useMemo(() => {
        if (!data) return {};
        
        const getButtonHoverStyles = () => {
            const intensity = data.buttonHoverIntensity || 10;
            
            switch (data.buttonHoverEffect) {
                case 'scale':
                    return {
                        transform: 'scale(1)',
                        transition: 'all 0.2s ease',
                        '--hover-transform': `scale(${1 + intensity/100})`,
                        '--hover-opacity': data.buttonHoverOpacity
                    };
                case 'lift':
                    return {
                        transform: 'translateY(0)',
                        transition: 'all 0.2s ease',
                        '--hover-transform': `translateY(-${intensity/2}px)`,
                        '--hover-opacity': data.buttonHoverOpacity
                    };
                default:
                    return {
                        transition: 'opacity 0.2s ease',
                        '--hover-opacity': data.buttonHoverOpacity
                    };
            }
        };

        return {
            backgroundColor: data.buttonColor,
            color: data.buttonTextColor,
            borderRadius: `${data.buttonRadius}px`,
            fontSize: `${data.buttonTextSize}px`,
            border: `${data.buttonBorderWidth}px solid ${data.buttonBorderColor}`,
            boxShadow: data.buttonShadowSpread > 0 
                ? `0 ${data.buttonShadowSpread}px ${data.buttonShadowSpread * 2}px ${data.buttonShadowColor}`
                : 'none',
            ...getButtonHoverStyles()
        };
    }, [data]);

    if (!data) return null;

    return (
        <div 
            className="linksy-container"
            style={{
                backgroundColor: data.backgroundColor,
                color: data.textColor,
                fontFamily: data.fontFamily
            }}
        >
            <LinksyHeader 
                shopIcon={data.shopIcon}
                titleSize={data.titleSize}
                shopName={data.shopName}
                bioSize={data.bioSize}
                bio={data.bio}
            />

            <SocialIcons 
                socialLinks={data.socialLinks}
                textColor={data.textColor}
            />

            <div className="tabs-container">
                <button 
                    className={`tab ${activeTab === 'links' ? 'active' : ''}`}
                    onClick={() => setActiveTab('links')}
                    style={{
                        color: data.textColor,
                    }}
                >
                    Links
                </button>
                <button 
                    className={`tab ${activeTab === 'shop' ? 'active' : ''}`}
                    onClick={() => setActiveTab('shop')}
                    style={{
                        color: data.textColor,
                    }}
                >
                    Shop
                </button>
            </div>

            {activeTab === 'links' ? (
                <LinksyLinks 
                    links={data.links || []}
                    buttonStyle={buttonStyle}
                />
            ) : (
                <div className="linksy-shop">
                    {data.selectedListings?.length > 0 ? (
                        <div className="linksy-listings">
                            {data.selectedListings.map((listing) => (
                                <a 
                                    key={listing.listing_id}
                                    href={listing.url}
                                    className="linksy-listing"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={buttonStyle}
                                >
                                    <img 
                                        src={listing.images?.[0]?.url_fullxfull || listing.images?.[0]?.url_fullxfull} 
                                        alt={listing.title}
                                    />
                                    <div className="listing-info">
                                        <h3 style={{ color: data.buttonTextColor, fontSize: `${data.buttonTextSize}px` }}>
                                            {decode(listing.title)}
                                        </h3>
                                        <div className="listing-meta">
                                            <span className="favorites">
                                                <FaHeart /> {listing.num_favorers}
                                            </span>
                                            <span className="price">
                                                ${(listing.price.amount / listing.price.divisor).toFixed(2)}
                                            </span>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    ) : (
                        <p style={{ textAlign: 'center', marginTop: '20px' }}>Come back soon to see my shop!</p>
                    )}
                </div>
            )}

            <div className="linksy-footer">
                <button 
                    onClick={() => navigate('/')} 
                    style={{ 
                        cursor: 'pointer'
                    }}
                >
                    Made with Linksy
                </button>
            </div>
        </div>
    );
}

export default memo(Linksy);
