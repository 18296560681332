import { useState, useEffect, useRef } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './Profile.css';

function Profile({ shopName }) {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef();
    const buttonRef = useRef();
    const navigate = useNavigate();
    const auth = getAuth();
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current && 
                !menuRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setShowMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleDeleteAccount = async () => {
        if (!window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
            return;
        }

        setIsDeleting(true);
        try {
            const functions = getFunctions();
            const deleteAccount = httpsCallable(functions, 'deleteUserAccount');
            
            await deleteAccount();
            navigate('/', { replace: true });
        } catch (error) {
            console.error('Error deleting account:', error);
            alert(`Failed to delete account: ${error.message}. Please try again.`);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className="profile-container">
            <button 
                ref={buttonRef}
                className="profile-button" 
                onClick={() => setShowMenu(!showMenu)}
                title={shopName}
            >
                <div className="profile-name">{`@${shopName}`}</div>
            </button>

            {showMenu && (
                <div ref={menuRef} className="profile-menu">
                    <button onClick={handleSignOut}>Sign Out</button>
                    <button 
                        onClick={handleDeleteAccount} 
                        className="delete-account"
                        disabled={isDeleting}
                    >
                        {isDeleting ? 'Deleting...' : 'Delete Account'}
                    </button>
                </div>
            )}
        </div>
    );
}

export default Profile;