import './Auth.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';

function Auth() {
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Check if user is already logged in
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/app/admin');
            }
        });

        // Set email from state if provided
        if (location.state?.email) {
            setFormData(prev => ({
                ...prev,
                email: location.state.email
            }));
        }
        if (location.state?.message) {
            setMessage(location.state.message);
        }

        return () => unsubscribe();
    }, [location.state, navigate]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(
                auth,
                formData.email,
                formData.password
            );
            navigate('/app/admin');
        } catch (error) {
            setMessage('Invalid email or password');
        }
    };

    const handleCreateLinksy = () => {
        navigate('/app/onboarding');
    };

    return (
        <div className="auth-container">
            <div className="auth-form">
                <div>
                    <h2>Sign In</h2>
                    {message && <p className="auth-message">{message}</p>}
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input 
                            type="email" 
                            id="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Enter your email" 
                            required 
                        />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input 
                            type="password" 
                            id="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder="Enter your password" 
                            required 
                        />
                    </div>

                    <div className="forgot-password">
                        {/* TODO: Add forgot password functionality */}
                        <p>Forgot Password?</p>
                    </div>

                    <button type="submit" className="auth-button">
                        Sign In
                    </button>
                </form>

                <div className="divider">OR</div>

                <button 
                    onClick={handleCreateLinksy}
                    className="signup-button"
                >
                    Create Your Linksy
                </button>
            </div>
        </div>
    );
}

export default Auth;
