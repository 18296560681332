// Import all font packages
import '@fontsource/roboto';
import '@fontsource/open-sans';
import '@fontsource/lato';
import '@fontsource/montserrat';
import '@fontsource/poppins';
import '@fontsource/playfair-display';
import '@fontsource/merriweather';
import '@fontsource/lora';
import '@fontsource/pacifico';
import '@fontsource/dancing-script';
import '@fontsource/hanken-grotesk';

export const fontOptions = [
  {
    label: 'Sans Serif',
    options: [
      { value: 'Roboto, sans-serif', label: 'Roboto' },
      { value: 'Open Sans, sans-serif', label: 'Open Sans' },
      { value: 'Lato, sans-serif', label: 'Lato' },
      { value: 'Montserrat, sans-serif', label: 'Montserrat' },
      { value: 'Poppins, sans-serif', label: 'Poppins' },
      { value: 'Hanken Grotesk, sans-serif', label: 'Hanken Grotesk' }
    ]
  },
  {
    label: 'Serif',
    options: [
      { value: 'Playfair Display, serif', label: 'Playfair Display' },
      { value: 'Merriweather, serif', label: 'Merriweather' },
      { value: 'Lora, serif', label: 'Lora' }
    ]
  },
  {
    label: 'Display',
    options: [
      { value: 'Pacifico, cursive', label: 'Pacifico' },
      { value: 'Dancing Script, cursive', label: 'Dancing Script' }
    ]
  }
];

// Helper function to get all font families as a string
export const getAllFontFamilies = () => {
  return fontOptions
    .flatMap(category => category.options)
    .map(font => font.value)
    .join('|');
}; 