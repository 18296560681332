import { useState } from 'react';
import './LinksManager.css';

function LinksManager({ links = [], onUpdate }) {
    const [newLink, setNewLink] = useState({ title: '', url: '' });

    const handleAddLink = (e) => {
        e.preventDefault();
        if (!newLink.title || !newLink.url) return;

        onUpdate([...links, newLink]);
        setNewLink({ title: '', url: '' });
    };

    const handleRemoveLink = (index) => {
        const updatedLinks = links.filter((_, i) => i !== index);
        onUpdate(updatedLinks);
    };

    const handleReorder = (index, direction) => {
        const updatedLinks = [...links];
        const newIndex = index + direction;

        if (newIndex < 0 || newIndex >= links.length) return;

        [updatedLinks[index], updatedLinks[newIndex]] = 
        [updatedLinks[newIndex], updatedLinks[index]];

        onUpdate(updatedLinks);
    };

    return (
        <div className="links-manager">
            
            <form onSubmit={handleAddLink} className="add-link-form">
                <h4>Manage Links</h4>
                
                <input
                    type="text"
                    placeholder="Link Title"
                    value={newLink.title}
                    onChange={(e) => setNewLink({...newLink, title: e.target.value})}
                />
                <input
                    type="url"
                    placeholder="URL"
                    value={newLink.url}
                    onChange={(e) => setNewLink({...newLink, url: e.target.value})}
                />
                <button type="submit">Add Link</button>
            </form>

            <div className="links-list">
                {links.map((link, index) => (
                    <div key={index} className="link-item">
                        <div className="link-info">
                            <div className="link-title">{link.title}</div>
                            <div className="link-url">{link.url}</div>
                        </div>
                        <div className="link-actions">
                            <button 
                                onClick={() => handleReorder(index, -1)}
                                disabled={index === 0}
                            >↑</button>
                            <button 
                                onClick={() => handleReorder(index, 1)}
                                disabled={index === links.length - 1}
                            >↓</button>
                            <button 
                                onClick={() => handleRemoveLink(index)}
                                className="remove-link"
                            >×</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LinksManager; 