import './Onboarding.css';

function Onboarding() {
    const clientId = 'm0hnre33ccvxgqxtg4cgvs1b';
    const redirectUri = 'https://my-linksy.com/app/callback';
    const scopes = 'address_r address_w billing_r cart_r cart_w email_r favorites_r favorites_w feedback_r listings_d listings_r listings_w profile_r profile_w recommend_r recommend_w shops_r transactions_r transactions_w';

    // Generate a random string for state
    const generateState = () => {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    };

    // Generate code verifier
    const generateCodeVerifier = () => {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);
        return base64URLEncode(array);
    };

    // Base64URL encode an array
    const base64URLEncode = (buffer) => {
        return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    };

    // Generate code challenge from verifier
    const generateCodeChallenge = async (codeVerifier) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = await window.crypto.subtle.digest('SHA-256', data);
        return base64URLEncode(digest);
    };

    // Handle the OAuth redirect
    const handleEtsyAuth = async () => {
        const state = generateState();
        const codeVerifier = generateCodeVerifier();
        const codeChallenge = await generateCodeChallenge(codeVerifier);

        // Store state and code verifier in sessionStorage for verification in callback
        sessionStorage.setItem('etsy_oauth_state', state);
        sessionStorage.setItem('etsy_code_verifier', codeVerifier);

        // Construct the authorization URL
        const authUrl = new URL('https://www.etsy.com/oauth/connect');
        const params = {
            response_type: 'code',
            client_id: clientId,
            redirect_uri: redirectUri,
            scope: scopes,
            state: state,
            code_challenge: codeChallenge,
            code_challenge_method: 'S256'
        };

        authUrl.search = new URLSearchParams(params).toString();

        // Redirect to Etsy's OAuth page
        window.location.href = authUrl.toString();
    };

    return (
        <div className="onboarding-container">
            <h1>Connect Your Etsy Shop</h1>
            <button onClick={handleEtsyAuth} className="connect-button">
                Connect with Etsy
            </button>
            <p className="note">Linksy is exclusively for Etsy sellers</p>
        </div>
    );
}

export default Onboarding;