import { useState, useEffect } from 'react';
import './SocialManager.css';
import { FaPlus, FaTrash, FaPencilAlt } from 'react-icons/fa';
import { SOCIAL_CHANNELS } from '../Config/social';

function SocialManager({ socialLinks = [], onUpdate, userData }) {
    const [localLinks, setLocalLinks] = useState(socialLinks);
    const [showAddForm, setShowAddForm] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState('');
    const [url, setUrl] = useState('');
    const [editingLink, setEditingLink] = useState(null);

    // Reset local state when props change
    useEffect(() => {
        setLocalLinks(socialLinks);
    }, [socialLinks]);

    const availableChannels = Object.keys(SOCIAL_CHANNELS).filter(
        channel => !localLinks.find(link => link.platform === channel)
    );

    const formatUrl = (platform, inputUrl) => {
        if (!inputUrl) return '';
        
        // Remove any existing protocol and www
        let cleanUrl = inputUrl.replace(/^(https?:\/\/)?(www\.)?/, '').trim();
        
        switch (platform) {
            case 'email':
                return `mailto:${cleanUrl.replace(/^mailto:/i, '')}`;
                
            case 'instagram':
                // Handle both full URLs and just usernames
                cleanUrl = cleanUrl.replace(/^instagram\.com\//, '').replace(/^@/, '');
                return `https://instagram.com/${cleanUrl}`;
                
            case 'etsy':
                // Handle various Etsy URL patterns
                cleanUrl = cleanUrl
                    .replace(/^etsy\.com\/(shop\/)?/, '')
                    .replace(/^shop\//, '');
                return `https://etsy.com/shop/${cleanUrl}`;
                
            case 'tiktok':
                // Handle both full URLs and usernames
                cleanUrl = cleanUrl
                    .replace(/^tiktok\.com\/@?/, '')
                    .replace(/^@/, '');
                return `https://tiktok.com/@${cleanUrl}`;
                
            default:
                // For other platforms, just ensure the URL has a protocol
                return cleanUrl.startsWith('http') ? cleanUrl : `https://${cleanUrl}`;
        }
    };

    const getUrlPreview = (platform, inputUrl) => {
        if (!inputUrl) return '';
        
        switch (platform) {
            case 'instagram':
                return `@${inputUrl.replace(/^.*instagram\.com\//, '').replace(/^@/, '')}`;
            case 'etsy':
                return `Shop: ${inputUrl.replace(/^.*etsy\.com\/(shop\/)?/, '')}`;
            case 'tiktok':
                return `@${inputUrl.replace(/^.*tiktok\.com\/@?/, '').replace(/^@/, '')}`;
            case 'email':
                return inputUrl.replace(/^mailto:/, '');
            default:
                return inputUrl.replace(/^(https?:\/\/)?(www\.)?/, '');
        }
    };

    const getInputHelp = (platform) => {
        switch (platform) {
            case 'instagram':
                return 'Enter your Instagram username or full profile URL';
            case 'etsy':
                return 'Enter your shop name or full Etsy shop URL';
            case 'tiktok':
                return 'Enter your TikTok username (with or without @) or full profile URL';
            case 'email':
                return 'Enter your email address';
            default:
                return SOCIAL_CHANNELS[platform]?.placeholder || 'Enter URL';
        }
    };

    const handleAdd = (e) => {
        e.preventDefault();
        if (!selectedChannel || !url) return;

        const formattedUrl = formatUrl(selectedChannel, url);
        const newLinks = [...localLinks, { platform: selectedChannel, url: formattedUrl }];
        onUpdate(newLinks);
        
        setShowAddForm(false);
        setSelectedChannel('');
        setUrl('');
    };

    const handleDelete = (platform) => {
        const newLinks = localLinks.filter(link => link.platform !== platform);
        onUpdate(newLinks);
    };

    const handleUpdateLink = (platform) => {
        const formattedUrl = formatUrl(platform, editingLink.url);
        const updatedLinks = localLinks.map(link => 
            link.platform === platform ? { ...link, url: formattedUrl } : link
        );
        onUpdate(updatedLinks);
        setEditingLink(null);
    };

    return (
        <div className="social-manager">
            <h4>Social Links</h4>
            
            <div className="social-links-list">
                {localLinks.map(link => {
                    const Channel = SOCIAL_CHANNELS[link.platform].icon;
                    const isEditing = editingLink?.platform === link.platform;

                    return (
                        <div key={link.platform} className={`social-link-item ${isEditing ? 'editing' : ''}`}>
                            <Channel className="social-icon" />
                            <span className="social-platform">{SOCIAL_CHANNELS[link.platform].name}</span>
                            {isEditing ? (
                                <>
                                    <div className="input-group">
                                        <input 
                                            className="edit-input"
                                            type={link.platform === 'email' ? 'email' : 'text'}
                                            value={editingLink.url.replace(/^(https?:\/\/)?(www\.)?/, '')}
                                            onChange={(e) => setEditingLink({
                                                ...editingLink,
                                                url: e.target.value
                                            })}
                                            placeholder={getInputHelp(link.platform)}
                                        />
                                        <small className="input-help">
                                            {getInputHelp(link.platform)}
                                        </small>
                                    </div>
                                    <div className="edit-actions">
                                        <button 
                                            onClick={() => handleUpdateLink(link.platform)}
                                            disabled={editingLink.url === link.url}
                                        >
                                            Save
                                        </button>
                                        <button onClick={() => setEditingLink(null)}>
                                            Cancel
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <span className="link-url">
                                        {getUrlPreview(link.platform, link.url)}
                                    </span>
                                    <div className="action-buttons">
                                        <button 
                                            onClick={() => setEditingLink(link)}
                                            className="edit-button"
                                        >
                                            <FaPencilAlt />
                                        </button>
                                        <button 
                                            className="delete-button"
                                            onClick={() => handleDelete(link.platform)}
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>

            {!showAddForm ? (
                <button 
                    className="add-channel-button"
                    onClick={() => setShowAddForm(true)}
                    disabled={availableChannels.length === 0}
                >
                    <FaPlus /> Add Channel
                </button>
            ) : (
                <form onSubmit={handleAdd} className="add-channel-form">
                    <select 
                        value={selectedChannel}
                        onChange={(e) => setSelectedChannel(e.target.value)}
                        required
                    >
                        <option value="">Select a platform</option>
                        {availableChannels.map(channel => (
                            <option key={channel} value={channel}>
                                {SOCIAL_CHANNELS[channel].name}
                            </option>
                        ))}
                    </select>
                    <div className="input-group">
                        <input
                            type={selectedChannel === 'email' ? 'email' : 'text'}
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            placeholder={selectedChannel ? getInputHelp(selectedChannel) : 'Enter URL'}
                            required
                        />
                        {selectedChannel && (
                            <small className="input-help">
                                {getInputHelp(selectedChannel)}
                            </small>
                        )}
                    </div>
                    <div className="form-buttons">
                        <button type="submit">Add</button>
                        <button type="button" onClick={() => setShowAddForm(false)}>Cancel</button>
                    </div>
                </form>
            )}
        </div>
    );
}

export default SocialManager;