function Privacy() {
    return (
        <div className="legal-container" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
            <h1>Privacy Policy</h1>
            <p>Last updated: January 2024</p>
            
            <section>
                <p>This Privacy Policy describes how Understudy Technologies LLC ("we", "us", or "our") collects, uses, and shares your personal information when you use Linksy (the "Service").</p>
                
                <h2>Information We Collect</h2>
                <ul>
                    <li>Account information: email address and password</li>
                    <li>Etsy store information through the Etsy API</li>
                    <li>Usage data and analytics</li>
                    <li>Information you choose to display on your Linksy page</li>
                </ul>

                <h2>How We Use Your Information</h2>
                <ul>
                    <li>To provide and maintain the Service</li>
                    <li>To communicate with you about the Service</li>
                    <li>To improve and optimize the Service</li>
                    <li>To comply with legal obligations</li>
                </ul>

                <h2>Data Sharing</h2>
                <p>We share your information with:</p>
                <ul>
                    <li>Etsy, as necessary for the Service functionality</li>
                    <li>Service providers who assist in operating our Service</li>
                    <li>Law enforcement when required by law</li>
                </ul>

                <h2>Contact Us</h2>
                <p>For privacy-related questions, please contact us at hello@understudy.app</p>
            </section>
        </div>
    );
}

export default Privacy;