import { FaFacebook, FaInstagram, FaTwitter, FaPinterest, FaTiktok, FaEnvelope, FaYoutube, FaEtsy } from 'react-icons/fa';

export const SOCIAL_CHANNELS = {
    etsy: { 
        icon: FaEtsy, 
        name: 'Etsy', 
        placeholder: 'etsy.com/shop' 
    },
    instagram: { 
        icon: FaInstagram, 
        name: 'Instagram', 
        placeholder: 'instagram.com/username' 
    },
    facebook: { 
        icon: FaFacebook, 
        name: 'Facebook', 
        placeholder: 'facebook.com/page' 
    },
    twitter: { 
        icon: FaTwitter, 
        name: 'Twitter', 
        placeholder: 'twitter.com/username' 
    },
    pinterest: { 
        icon: FaPinterest, 
        name: 'Pinterest', 
        placeholder: 'pinterest.com/username' 
    },
    tiktok: { 
        icon: FaTiktok, 
        name: 'TikTok', 
        placeholder: 'tiktok.com/@username' 
    },
    youtube: { 
        icon: FaYoutube, 
        name: 'YouTube', 
        placeholder: 'youtube.com/channel' 
    },
    email: { 
        icon: FaEnvelope, 
        name: 'Email', 
        placeholder: 'Enter your email address' 
    }
}; 