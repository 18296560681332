import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

// Home
import Home from './Home/Home';
import Terms from './Home/Terms';
import Privacy from './Home/Privacy';

// Auth
import Auth from './App/Auth/Auth';
import Onboarding from './App/Auth/Onboarding';
import Callback from './App/Auth/Callback';
import SignUp from './App/Auth/SignUp';

// Admin
import Admin from './App/Admin/Admin';

// Public
import PublicLinksy from './App/Public/PublicLinksy';

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/:shopName' element={<PublicLinksy />} />
                
                <Route path='/' element={<Home />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/privacy' element={<Privacy />} />
                <Route path='/app/auth' element={<Auth />} />
                <Route path='/app/onboarding' element={<Onboarding />} />
                <Route path='/app/callback' element={<Callback />} />
                <Route path='/app/signup' element={<SignUp />} />
                <Route path='/app/admin' element={<Admin />} />        
            </Routes>
        </Router>
    );
}

export default App;
