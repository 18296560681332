import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import './SignUp.css';
import { doc, getDoc, setDoc, Timestamp, deleteDoc } from 'firebase/firestore';

function SignUp() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState(null);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                const userId = params.get('userId');
                
                if (!userId) {
                    throw new Error('No user ID provided');
                }

                const userDoc = await getDoc(doc(db, 'users', userId));
                
                if (!userDoc.exists()) {
                    throw new Error('User data not found');
                }

                setUserData(userDoc.data());
                setLoading(false);
            } catch (err) {
                console.error('Error fetching user data:', err);
                setError(err.message);
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            if (formData.password !== formData.confirmPassword) {
                throw new Error('Passwords do not match');
            }

            // Create Firebase auth user
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                userData.email,
                formData.password
            );

            const firebaseUid = userCredential.user.uid;
            const etsyUserId = userData.etsyUserId;

            // Get the token document
            const tokenDoc = await getDoc(doc(db, 'tokens', etsyUserId));
            if (!tokenDoc.exists()) {
                throw new Error('Token data not found');
            }

            // Create new token document with Firebase UID
            await setDoc(doc(db, 'tokens', firebaseUid), {
                ...tokenDoc.data(),
                uid: firebaseUid
            });

            // Delete old token document
            await deleteDoc(doc(db, 'tokens', etsyUserId));

            // Update user document with Firebase UID
            await setDoc(doc(db, 'users', firebaseUid), {
                ...userData,
                uid: firebaseUid,
                username: userData.shop_name
            });

            // Delete old user document
            await deleteDoc(doc(db, 'users', etsyUserId));

            // Create initial linksy record
            await setDoc(doc(db, 'linksys', firebaseUid), {
                backgroundColor: "#FFFFFF",
                bio: userData.shop_name,
                bioSize: 12,
                buttonColor: "#FFFFFF",
                buttonRadius: 8,
                buttonTextColor: "#000000",
                buttonTextSize: 14,
                createdAt: Timestamp.now(),
                fontFamily: "Lora",
                shopName: userData.shop_name,
                shopIcon: userData.shop_icon,
                textColor: "#000000",
                titleSize: 20,
                uid: firebaseUid,
                etsyUserId: etsyUserId,
                buttonBorderColor: "#0B1215",
                buttonBorderWidth: 1,
                buttonShadowColor: "#0B1215",
                buttonShadowSpread: 1,
                buttonHoverOpacity: 0.9,
                buttonHoverEffect: 'scale',
                buttonHoverIntensity: 2,
            });

            navigate('/app/admin');

        } catch (err) {
            console.error('Signup error:', err);
            setError(err.message);
        }
    };

    if (loading) {
        return <div className="signup-container">Loading...</div>;
    }

    if (error) {
        return <div className="signup-container error">{error}</div>;
    }

    return (
        <div className="signup-container">
            <form onSubmit={handleSubmit} className="signup-form">
                <h2>Complete Your Registration</h2>
                
                <div className="form-group readonly">
                    <label>Email</label>
                    <input 
                        type="email" 
                        value={userData.email} 
                        disabled 
                    />
                </div>

                <div className="form-group readonly">
                    <label>Shop Name</label>
                    <input 
                        type="text" 
                        value={userData.shop_name} 
                        disabled 
                    />
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <input 
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Confirm Password</label>
                    <input 
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <button type="submit" className="signup-submit">
                    Create Account
                </button>
            </form>
        </div>
    );
}

export default SignUp; 